import { useEffect, useMemo } from "react";
import {
  createColumnHelper,
  ColumnDef,
} from "@tanstack/react-table";
import { Table } from "../../lib/table/Table";
import { useAppDispatch, useAppSelector } from "../../../store";
import { fetchInvoices, resetCurrentInvoice } from "../../../store/slices/invoices";
import { IInvoice } from "../../../api/invoices/types";
import { formatAmount } from "../../../helpers/formatAmount";
import { ContractCell } from "../../ContractCell/ContractCell";
import dayjs from "dayjs";
import { getFullTime } from "../../../helpers/getFullTime";

const columnHelper = createColumnHelper<IInvoice>();

export function InvoiceTable() {
  const { invoices, currentInvoice } = useAppSelector((state) => state.invoices);
  const dispatch = useAppDispatch()

  useEffect(() => {
    return () => {
      dispatch(resetCurrentInvoice());
    };
  }, []);

  const columns = useMemo<Array<ColumnDef<IInvoice, string>>>(
    () => [
      columnHelper.accessor("invoice_date", {
        header: "Дата",
        cell: (info) => getFullTime(info.renderValue()),
        enableSorting: true,
      }),
      columnHelper.accessor("invoice_number", {
        header: "Номер",
      }),
      columnHelper.accessor("a101_org_name", {
        header: "Организация",
      }),
      columnHelper.accessor("included_document_number", {
        header: "Номер вх.",
      }),
      columnHelper.accessor("included_document_date", {
        header: "Дата вх.",
        cell: (info) => dayjs(info.renderValue()).format("DD.MM.YYYY"),
        enableSorting: true,
      }),
      columnHelper.accessor("amount", {
        header: "Сумма",
        cell: (info) => formatAmount(info.getValue()),
      }),
      columnHelper.accessor("contract_name", {
        header: "Договор",
        cell: (info) => ContractCell(info, String(info.cell.row.original.contract_id), "268px"),
      }),
    ],
    []
  );

  return (
    <Table
      isGuid={true}
      data={invoices}
      columns={columns}
      fetchFunction={fetchInvoices}
      lastViewedDocument={currentInvoice?.guid}
    />
  );
}
