import { useSearchParams } from "react-router-dom";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  ButtonGroup,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import {
  OutlineButton,
  PrimaryButton,
} from "../../components/lib/button/Button";
import { BlockTitle } from "../../components/lib/title/Title";
import { ChakraTabs } from "../../components/lib/tabs/Tabs";
import { ChakraMenuList } from "../../components/lib/menu/ChakraMenuList";
import { MainLayout } from "../../components/MainLayout/MainLayout";
import { DocumentPageBlock } from "../../components/SingleOrderLetterComponents/DocumentPageTab/DocumentPageBlock";
import { AdditionalInformationBlock } from "../../components/SingleOrderLetterComponents/AdditionalInformationBlock";
import { FilesBlock } from "../../components/SingleOrderLetterComponents/FilesTab/FilesBlock";
import { singleOrderLetterPageTabs, singleOrderLetterPageTabsWithoutMaterials } from "../../shared/mock/purchasePageData";
import {ReactComponent as MenuIcon} from "../../images/svg/menu/more.svg";
import { ReactComponent as Document } from "../../images/svg/sidebar/document.svg";
import { MaterialsTable } from "../../components/SingleOrderLetterComponents/MaterialsTable";
import { DownloadFilesModal } from "../../components/Modal/ModalRight/DownloadFilesModal";
import { useEffect, useState } from "react";
import { RootState, useAppSelector } from "../../store";
import { attachFilesToDirrectionLetter, fetchSingleDirectionLetter } from "../../store/slices/directionLetters";
import { IFilesAttachResponse } from "../../api/files/types";

export function SingleOrderLetterPage({ goBack, id, dispatch, filesModal, setFilesModal }: any) {
  const [searchParams, setSearchParams] = useSearchParams();
  const tabIndex = Number(searchParams.get("tab"));

  const { currentDirectionLetter } = useAppSelector((state: RootState) => state.directionLetters);

  const setDefaultTabParam = (index: number): void => {
    index === 0
      ? searchParams.delete("tab")
      : searchParams.set("tab", String(index));

    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (id) {
      dispatch(fetchSingleDirectionLetter(id));
    }
  }, [dispatch, id]);

  const [files, setFiles] = useState(currentDirectionLetter?.files);
  
  useEffect(() => {
    setFiles(currentDirectionLetter?.files)
  }, [currentDirectionLetter?.files])

  const fileAttach = (files: File[]) => {
    if (currentDirectionLetter) {
      dispatch(attachFilesToDirrectionLetter({id: currentDirectionLetter.guid, files: files})).then((res: { payload: IFilesAttachResponse}) => {
        setFiles((res.payload as IFilesAttachResponse)?.data.files);
      });
    }
  }

  return (
    <MainLayout>
      <Flex direction="column" gap={10} p={6}>
        <Flex direction="column" gap={4}>
          <Breadcrumb
            fontSize={14}
            separator={<Box w={1} h={1} bg="#8089A2" borderRadius="50%" />}
          >
            <BreadcrumbItem>
              <BreadcrumbLink href="/main">Главная</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink href="/purchase">Закупки</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink href="/purchase/order-letters">
                Распорядительные письма
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage color="#8089A2">
              <BreadcrumbLink>
                {currentDirectionLetter?.number}
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>

          <Flex justify="space-between" align="center">
            <Flex align="center" gap={4}>
              <ArrowBackIcon boxSize={6} cursor="pointer" onClick={() => goBack("/purchase/order-letters")} />
              <BlockTitle>{`Распорядительное письмо ${currentDirectionLetter?.number}`}</BlockTitle>
            </Flex>
            <ButtonGroup spacing={3}>
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  icon={<MenuIcon color="red.500" />}
                  variant="outline"
                  borderColor="#FAD7D8"
                  _active={{ bg: "#FAD7D8", borderColor: "#F1868B" }}
                />
                <ChakraMenuList
                  list={[
                    {
                      title: "Отозвать оплату",
                    },
                    {
                      title: "Печать заявки на выплату аванса",
                    },
                    {
                      title: "Создать отражение факта поставки",
                    },
                  ]}
                />
              </Menu>
              <OutlineButton
                onClick={() => setFilesModal(true)}
                leftIcon={<Icon as={Document} fill={"#E8363D"} />}
              >
                Файлы
              </OutlineButton>
              <OutlineButton>Согласовать</OutlineButton>
              <OutlineButton>Перезапустить</OutlineButton>
              <PrimaryButton>Записать</PrimaryButton>
            </ButtonGroup>
          </Flex>

          <Tabs defaultIndex={tabIndex} onChange={setDefaultTabParam}>
            <ChakraTabs
              tabs={
                currentDirectionLetter?.limit_card_id
                  ? singleOrderLetterPageTabs
                  : singleOrderLetterPageTabsWithoutMaterials
              }
            />
            <TabPanels>
              <TabPanel px={0} pb={0}>
                <DocumentPageBlock />
              </TabPanel>
              {currentDirectionLetter?.limit_card_id && (
                <TabPanel px={0} pb={0}>
                  <MaterialsTable disabledChange={true} />
                </TabPanel>
              )}
              <TabPanel px={0} pb={0}>
                <AdditionalInformationBlock setFilesModal={setFilesModal} />
              </TabPanel>
              <TabPanel px={0} pb={0}>
                <FilesBlock />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>
      </Flex>
      <DownloadFilesModal
        title="Файлы"
        active={filesModal}
        onClose={() => setFilesModal(false)}
        receivedFiles={files}
        fileAttach={fileAttach}
      />
    </MainLayout>
  );
}
