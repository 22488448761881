import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppSelector } from "../../../store";
import { attachFilesToSingleWorkCompletedAct, fetchSingleWorkCompletedActs, fetchWorkCompletedActs, resetCurrenWorkCompletedAct } from "../../../store/slices/workCompletedActs";
import {
  createColumnHelper,
  CellContext,
  ColumnDef,
} from "@tanstack/react-table";
import { Box, Center, Flex, Text } from "@chakra-ui/react";
import { ExternalLinkIcon} from "@chakra-ui/icons";
import { Table } from "../../lib/table/Table";
import { ReactComponent as EditPen } from "../../../images/svg/edit/edit-pen.svg";
import { IWorkCompletedAct } from "../../../api/workCompletedActs/types";
import { formatAmount } from "../../../helpers/formatAmount";
import { Input } from "../../lib/input/Input";
import { DownloadFilesModal } from "../../Modal/ModalRight/DownloadFilesModal";
import { IFilesAttachResponse } from "../../../api/files/types";
import {ContractCell} from "../../ContractCell/ContractCell";
import { ProgressElement } from "../../SingleWorkCompletedActComponents/ProgressElement";

const columnHelper = createColumnHelper<IWorkCompletedAct>();

interface TitleProps {
  onChange: (inp: string) => void;
}

const TitleHeader = ({ onChange }: TitleProps) => {
  return (
    <Flex align="center" justify="space-between" w="full" gap="16px">
      <span>Договор</span>
      <Input
        small
        search
        placeholder="Поиск"
        onChange={(e) => onChange(e.target.value)}
      />
    </Flex>
  );
};

const NameCell = (info: CellContext<IWorkCompletedAct, string>) => (
  <Flex gap={4} justify="space-between">
    {info.getValue()}
    <Center w="16px">
      {info.row.original.edo && <EditPen fill="#2AB6A5" />}
    </Center>
  </Flex>
);

const ReconciliationCell = (info: CellContext<IWorkCompletedAct, string>) => {
  const progressBlocks = [
    { t: "РС", p: info.row.original.progress_rs },
    { t: "ТН", p: info.row.original.progress_tn },
    { t: "Бэк-офис", p: info.row.original.progress_back_office }
  ]
  return (
    <Flex gap="8px" flexDirection="column">
      {progressBlocks.map(el => <ProgressElement title={el.t} percent={el.p} isTableComponent={true} />)}
    </Flex>

  );
};

export function CompletedWorkActsTable({ dispatch, filesModal, setFilesModal }: any) {
  const { workCompletedActs } = useAppSelector(
    (state) => state.workCompletedActs
  );
  const { currentWorkCompletedAct } = useAppSelector(
    (state) => state.workCompletedActs
  );

  const [search, setSearch] = useState("");
  const [files, setFiles] = useState(currentWorkCompletedAct?.files ?? []);

  const fileAttach = (files: File[]) => {
    if (currentWorkCompletedAct) {
      dispatch(attachFilesToSingleWorkCompletedAct({id: currentWorkCompletedAct.id, files: files})).then((res: { payload: IFilesAttachResponse}) => {
        setFiles((res.payload as IFilesAttachResponse)?.data.files);
      });
    }
  }

  const onChange = (str: string) => {
    setSearch(str);
  };

  useEffect(() => {
    return () => {
      dispatch(resetCurrenWorkCompletedAct())
    }
  }, [])

  const ProjectCell = useCallback(
    ({ row: { original } }: CellContext<IWorkCompletedAct, string>) => (
      <>
        <Text noOfLines={1}>{original.project_name}</Text>
        <Box noOfLines={1} lineHeight="21px" fontSize={14} color="#8089a2">
          {original.executor}
        </Box>
      </>
    ),
    []
  );

  const FilesCell = ({ row: { original } }: CellContext<IWorkCompletedAct, string>) => {
    return(
    <Center cursor="pointer">
      <ExternalLinkIcon
        onClick={() => {
          setFilesModal(true);
          dispatch(fetchSingleWorkCompletedActs(original.id))
        }}
        color="#2AB6A5"
      />
    </Center>
    );
  };


  const columns = useMemo<Array<ColumnDef<IWorkCompletedAct, string>>>(
    () => [
      columnHelper.accessor("name", {
        header: "Название",
        cell: NameCell,
      }),
      columnHelper.accessor("contract_name", {
        header: () => TitleHeader({ onChange }),
        cell: (info) => ContractCell(info, info.row.original.contract_id),
      }),
      columnHelper.accessor("amount", {
        header: "Сумма",
        cell: (info) => formatAmount(info.getValue()),
      }),
      columnHelper.accessor("agreement", {
        header: "Согласование",
        cell: ReconciliationCell,
      }),
      {
        id: "project",
        header: "Проект и исполнитель",
        cell: ProjectCell,
      },
      columnHelper.accessor("file", {
        header: "Файлы",
        cell: FilesCell,
      }),
    ],
    [ProjectCell]
  );
  return (
    <>
      <Table
        data={
          search.trim() === ""
            ? workCompletedActs
            : workCompletedActs.filter((it) =>
                it.contract_name.toLowerCase().includes(search.toLowerCase())
              )
        }
        columns={columns}
        isWorkCompletedActs={true}
        fetchFunction={fetchWorkCompletedActs}
        lastViewedDocument={currentWorkCompletedAct?.id}
      />
      <DownloadFilesModal
        title="Файлы"
        active={filesModal}
        onClose={() => setFilesModal(false)}
        receivedFiles={files}
        fileAttach={fileAttach}
      />
    </>
  );
}

