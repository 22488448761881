import { createSlice } from "@reduxjs/toolkit";
import api from "../../api";
import { ICommonInitialState } from "../../shared/types/storeTypes";
import { createAsyncThunkInstance } from "../createAsyncThunkInstance";
import { IMainInfo, IMainInfoResponse } from "../../api/mainInfo/types";

interface IMainInfoState extends ICommonInitialState {
  mainInfo: IMainInfo | null;
}

const initialState: IMainInfoState = {
  mainInfo: null,
  isLoading: false,
  error: null,
};

export const fetchMainInfo = createAsyncThunkInstance<
  IMainInfoResponse,
  undefined
>("mainInfo/fetchMainInfo", api.mainInfo.getMainInfo);

const mainInfoSlice = createSlice({
  name: "mainInfo",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchMainInfo.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchMainInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.mainInfo = action.payload.data;
      });
  },
});

export const mainInfoReducer = mainInfoSlice.reducer;
