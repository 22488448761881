import { AxiosPromise } from "axios";
import { axiosInstance } from "../instance";
import {AccessActsEndpoints, ContractsEndpoints, RemarksEndpoints, WorkCompletedActsEndpoints} from "../endpoints";
import {
  IContractsResponse,
  ISingleContractResponse,
  ISingleContractWorkCompletedActsResponse,
} from "./types";
import {IAccessActsResponse} from "../accessActs/types";
import { ISingleContractConstructionsResponse, ISingleContractPaymentsResponse } from "../remarks/types";

export const getContracts = (): AxiosPromise<IContractsResponse> =>
  axiosInstance.get(ContractsEndpoints.CONTRACTS);

export const getArchiveContracts = (): AxiosPromise<IContractsResponse> =>
  axiosInstance.get(ContractsEndpoints.ARHIVE_CONTRACTS);

export const getSingleContract = (
  id: number
): AxiosPromise<ISingleContractResponse> =>
  axiosInstance.get(ContractsEndpoints.SINGLE_CONTRACT + `/${id}`);

export const getSingleContractWorkCompletedActs = (
  id: number
): AxiosPromise<ISingleContractWorkCompletedActsResponse> =>
  axiosInstance.get(
    ContractsEndpoints.SINGLE_CONTRACT +
      `/${id}/` +
      WorkCompletedActsEndpoints.WORK_COMPLETED_ACTS
  );

export const getSingleContractAccessActs = (
  id: number
): AxiosPromise<IAccessActsResponse> =>
  axiosInstance.get(
    ContractsEndpoints.SINGLE_CONTRACT +
    `/${id}` +
    AccessActsEndpoints.ACCESS_ACTS
  );

  export const getSingleContractConstructionRemarks = (
    id: number
  ): AxiosPromise<ISingleContractConstructionsResponse> =>
    axiosInstance.get(
      ContractsEndpoints.SINGLE_CONTRACT +
        `/${id}` +
        RemarksEndpoints.REMARKS_CONSTRUCTION
    );

export const getSingleContractPayments = (
  id: number
): AxiosPromise<ISingleContractPaymentsResponse> =>
  axiosInstance.get(
    ContractsEndpoints.SINGLE_CONTRACT +
      `/${id}/payments`
  );
