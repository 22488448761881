import {useEffect, useState} from "react";
import { useAppSelector } from "../../store";
import { FormikProps } from "formik";
import { Box, Flex, Input, Switch, Text } from "@chakra-ui/react";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import { InformationBlock } from "../InformationBlock/InformationBlock";
import {
  InformationItem,
  InformationItemInputGroup,
} from "../InformationBlock/InformationItem";
import { InformationItemDateInput } from "../InformationBlock/InformationItemDateInput";
import { ICreateWorkCompletedActRequest } from "../../api/workCompletedActs/types";
import { russianPatternCheck } from "../../helpers/russianPatternCheck";

interface CreateActInformationBlockProps {
  formik: FormikProps<ICreateWorkCompletedActRequest>;
  organization?: string;
  managerPoAInputDisabled?: boolean;
  accountantPoAInputDisabled?: boolean;
}

export function CreateActInformationBlock(
  props: CreateActInformationBlockProps
) {
  const { formik, organization = "", managerPoAInputDisabled = true, accountantPoAInputDisabled = true } = props;
  const { currentEstimate } = useAppSelector((state) => state.estimates);
  const { currentWorkCompletedAct } = useAppSelector((state) => state.workCompletedActs);

  const { setFieldValue } = formik;
  const managerPoA = formik.values.manager_name_power_of_attorney;
  const accountantPoA = formik.values.chief_accountant_power_of_attorney;
  const [isManagerPoAInputDisabled, setIsManagerPoAInputDisabled] =
    useState(managerPoAInputDisabled);
  const [isAccountantPoAInputDisabled, setIsAccountantPoAInputDisabled] =
    useState(accountantPoAInputDisabled);

  const handleManagerSwitchChange = () => {
    setIsManagerPoAInputDisabled(!isManagerPoAInputDisabled);
  };
  const handleAccountantSwitchChange = () => {
    setIsAccountantPoAInputDisabled(!isAccountantPoAInputDisabled);
  };

  useEffect(() => {
    (async () => {
      await setFieldValue("manager_name_power_of_attorney", isManagerPoAInputDisabled ? "" : managerPoA)
      await setFieldValue("chief_accountant_power_of_attorney", isAccountantPoAInputDisabled ? "" : accountantPoA)
    })()
  }, [isAccountantPoAInputDisabled, isManagerPoAInputDisabled, managerPoA, accountantPoA, setFieldValue]);

  return (
    <Flex direction="column">
      <InformationBlock>
        <InformationItem title="Организация">
          {currentWorkCompletedAct?.a101_org_name ?? organization}
        </InformationItem>
        <InformationItem title="Подрядчик" isHeading />
        <InformationItem title="Номер счёта" tooltipKey="working-completed-act_create-n-view_invoice_number">
          <InformationItemInputGroup>
            <Input
              id="invoice_number"
              name="invoice_number"
              value={formik.values.invoice_number}
              onChange={formik.handleChange}
              height="36px"
              placeholder="Введите номер счёта"
              borderColor="#E3E7EF"
              isInvalid={formik.values.invoice_number.trim() === ''}
            />
            <InformationItemDateInput
              id="invoice_date"
              name="invoice_date"
              value={formik.values.invoice_date}
              onChange={formik.handleChange}
              w="240px"
              placeholder="От"
              isInvalid={formik.values.invoice_date === ''}
            />
          </InformationItemInputGroup>
        </InformationItem>
        <InformationItem title="Акт номер" tooltipKey="working-completed-act_create-n-view_act_number">
          <InformationItemInputGroup>
            <Input
              id="act_number"
              name="act_number"
              value={formik.values.act_number}
              onChange={formik.handleChange}
              height="36px"
              placeholder="Введите акт номер"
              borderColor="#E3E7EF"
              isInvalid={formik.values.act_number.trim() === ''}
            />
            <InformationItemDateInput
              id="act_date"
              name="act_date"
              value={formik.values.act_date}
              onChange={formik.handleChange}
              w="240px"
              placeholder="От"
              isInvalid={formik.values.act_date === ''}
            />
          </InformationItemInputGroup>
        </InformationItem>
        <InformationItem title="СФ номер" tooltipKey="working-completed-act_create-n-view_sf_number">
          <InformationItemInputGroup>
            <Input
              id="sf_number"
              name="sf_number"
              value={formik.values.sf_number}
              onChange={formik.handleChange}
              height="36px"
              placeholder="Введите СФ номер"
              borderColor="#E3E7EF"
              isInvalid={formik.values.sf_number.trim() === ''}
            />
            <InformationItemDateInput
              id="sf_date"
              name="sf_date"
              value={formik.values.sf_date}
              onChange={formik.handleChange}
              w="240px"
              placeholder="От"
              isInvalid={formik.values.sf_date === ''}
            />
          </InformationItemInputGroup>
        </InformationItem>
        <InformationItem title="Подписи" isHeading />
        <InformationItem title="Руководитель" tooltipKey="working-completed-act_create-n-view_manager">
          <Input
            id="manager_name"
            name="manager_name"
            value={formik.values.manager_name}
            onChange={formik.handleChange}
            height="36px"
            placeholder="Введите ФИО руководителя"
            borderColor="#E3E7EF"
            position="absolute"
            top="50%"
            transform="translate(0, -50%)"
            isInvalid={(formik.values.manager_name.trim() === '' || !russianPatternCheck(formik.values.manager_name))}
          />
        </InformationItem>
        <InformationItem title="По доверенности">
          <InformationItemInputGroup>
            <Switch
              size="lg"
              colorScheme="teal"
              isChecked={!isManagerPoAInputDisabled}
              onChange={handleManagerSwitchChange}
            />
            <Input
              id="manager_name_power_of_attorney"
              name="manager_name_power_of_attorney"
              value={formik.values.manager_name_power_of_attorney}
              onChange={formik.handleChange}
              height="36px"
              placeholder="Введите доверенность"
              borderColor="#E3E7EF"
              disabled={isManagerPoAInputDisabled}
              _disabled={{ bg: "#E3E7EF", color: "#AAB0C1" }}
            />
          </InformationItemInputGroup>
        </InformationItem>
        <InformationItem title="Главный бухгалтер" tooltipKey="working-completed-act_create-n-view_chief_accountant">
          <Input
            id="chief_accountant"
            name="chief_accountant"
            value={formik.values.chief_accountant}
            onChange={formik.handleChange}
            height="36px"
            placeholder="Введите ФИО главного бухгалтера"
            borderColor="#E3E7EF"
            position="absolute"
            top="50%"
            transform="translate(0, -50%)"
            isInvalid={(formik.values.chief_accountant.trim() === '' || !russianPatternCheck(formik.values.chief_accountant))}
          />
        </InformationItem>
        <InformationItem title="По доверенности">
          <InformationItemInputGroup>
            <Switch
              size="lg"
              colorScheme="teal"
              isChecked={!isAccountantPoAInputDisabled}
              onChange={handleAccountantSwitchChange}
            />
            <Input
              id="chief_accountant_power_of_attorney"
              name="chief_accountant_power_of_attorney"
              value={formik.values.chief_accountant_power_of_attorney}
              onChange={formik.handleChange}
              height="36px"
              placeholder="Введите доверенность"
              borderColor="#E3E7EF"
              disabled={isAccountantPoAInputDisabled}
              _disabled={{ bg: "#E3E7EF", color: "#AAB0C1" }}
            />
          </InformationItemInputGroup>
        </InformationItem>
        <InformationItem>
          <Box height="28px"></Box>
        </InformationItem>
        <InformationItem alertItem>
          <Box py="8px">
          <Flex
            h="32px"
            gap={2}
            align="center"
            p="8px 12px"
            borderRadius="6px"
            bg="#FEE7AC"
          >
            <InfoOutlineIcon />
            <Text fontSize="14px">
              Если требуется, не забудь приложить акт о переработанных материалах
            </Text>
          </Flex>
          </Box>
        </InformationItem>

        <InformationItem title="Отчётный период">
          <InformationItemInputGroup>
            <InformationItemDateInput
              id="date_from"
              name="date_from"
              value={formik.values.date_from}
              onChange={formik.handleChange}
              placeholder="С"
              isInvalid={formik.values.date_from === ''}
            />
            <InformationItemDateInput
              id="date_to"
              name="date_to"
              value={formik.values.date_to}
              onChange={formik.handleChange}
              placeholder="По"
              isInvalid={formik.values.date_to === ''}
            />
          </InformationItemInputGroup>
        </InformationItem>
      </InformationBlock>
    </Flex>
  );
}
