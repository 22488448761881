import { ITab } from "../../components/lib/tabs/Tabs";

export const documentsInProgressPageTabs: ITab[] = [
  {
    id: 1,
    href: "completed-work-acts",
    label: "Акты выполненных работ",
    // notice: 1,
  },
  { id: 2, href: "invoices", label: "Счёт-фактуры", /* notice: 1,*/},
  { id: 3, href: "payment", label: "Оплата"},
];
