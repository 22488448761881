import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  ButtonGroup,
  Flex,
} from "@chakra-ui/react";
import { MainLayout } from "../../components/MainLayout/MainLayout";
import { ArrowBackIcon, DownloadIcon } from "@chakra-ui/icons";
import { BlockTitle } from "../../components/lib/title/Title";
import { SingleSettlementsActInformationBlock } from "../../components/SingleSettlementsActBlock/SingleSettlementsActInformationBlock";
import { Comment } from "../../components/Comment/Comment";
import { OutlineButton, PrimaryButton } from "../../components/lib/button/Button";
import { useEffect, useState } from "react";
import { fetchReconciliationPrintForm, fetchReconciliationStatement, updateReconciliatonStatement } from "../../store/slices/reconciliationStatement";
import { RootState, useAppSelector } from "../../store";
import { FullScreenWaitingModal } from "../../components/Modal/FullScreenWaitingModal";
import { useUniversalDownloadFile } from "../../helpers/useUniversalDownloadFile";

export function SingleSettlementsActPage({ goBack, id, dispatch }: any) {
  const [isSpinnerRuning, setIsSpinnerRuning] = useState(false);

  const { downloadFile } = useUniversalDownloadFile();

  const { reconciliationStatement } = useAppSelector(
    (state: RootState) => state.reconciliation
  );

  const [dataState, setDataState] = useState({
    counterparty_comment: "",
    сounterparty_disagreements: "",
  });

  function handleChangeComment(comment_value: string, state: string) {
    setDataState((dataState) => ({
      ...dataState,
      [state]: comment_value,
    }));
  }

  useEffect(() => {
    dispatch(fetchReconciliationStatement(id))
  }, [dispatch, id])

  return (
    <MainLayout>
      <Flex direction="column" gap={10} p={6}>
        <Flex direction="column" gap={4}>
          <Breadcrumb
            fontSize={14}
            separator={<Box w={1} h={1} bg="#8089A2" borderRadius="50%" />}
          >
            <BreadcrumbItem>
              <BreadcrumbLink href="/profile">Главная</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink href="/add-request">
                Доп. запросы и Визы
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink color="#8089A2">Акт сверки</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          <Flex justify="space-between" align="center">
            <Flex align="center" gap={4}>
              <ArrowBackIcon boxSize={6} cursor="pointer" onClick={() => goBack("/add-request")} />
              <BlockTitle>Акт сверки</BlockTitle>
            </Flex>
            <ButtonGroup spacing={3}>
              <OutlineButton
                leftIcon={<DownloadIcon/>}
                gap={2}
                isDisabled={false}
                onClick={() => downloadFile(id, setIsSpinnerRuning, fetchReconciliationPrintForm)}
                >
                Скачать
              </OutlineButton>
              <PrimaryButton
                isDisabled={dataState.counterparty_comment === "" || dataState.сounterparty_disagreements === ""}
                onClick={() => dispatch(updateReconciliatonStatement({ params: dataState, id }))}
              >
                Записать
              </PrimaryButton>
            </ButtonGroup>
          </Flex>
          <Flex gap="16px">
            <Flex h="max-content" direction="column" w="100%">
              <SingleSettlementsActInformationBlock />
            </Flex>
            <Flex gap="16px" minW="504px" direction="column">
              <Comment
                isEdited={true}
                title="Комментарий контрагента"
                currentComment={reconciliationStatement?.counterparty_comment}
                handleChangeComment={(value: string) => handleChangeComment(value, "counterparty_comment")}
              />
              <Comment
                isEdited={true}
                title="Разногласия контрагента"
                currentComment={reconciliationStatement?.сounterparty_disagreements}
                handleChangeComment={(value: string) => handleChangeComment(value, "сounterparty_disagreements")}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <FullScreenWaitingModal openModal={isSpinnerRuning} />
    </MainLayout>
  );
}
