import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { RootState, useAppSelector } from "../../store";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import {
  fetchSingleAccessAct,
  resetCurrentAccessAct,
} from "../../store/slices/accessActs";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { BlockTitle } from "../../components/lib/title/Title";
import { ChakraTabs } from "../../components/lib/tabs/Tabs";
import { MainLayout } from "../../components/MainLayout/MainLayout";
import { SingleAccessActInformationBlock } from "../../components/SingleAccessActComponents/SingleAccessActInformationBlock";
import { BuildingObjectsBlock } from "../../components/SingleAccessActComponents/BuildingObjects/BuildingObjectsBlock";
import { FilesBlock } from "../../components/SingleAccessActComponents/Files/FilesBlock";
import { singleAccessActPageTabs } from "../../shared/mock/myDocumentsPageData";

export function SingleAccessActPage({ dispatch, id, goBack }: any) {
  const { currentAccessAct } = useAppSelector(
    (state: RootState) => state.accessActs
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const tabIndex = Number(searchParams.get("tab"));

  const setDefaultTabParam = (index: number): void => {
    index === 0
      ? searchParams.delete("tab")
      : searchParams.set("tab", String(index));

    setSearchParams(searchParams);
  };

  useEffect(() => {
    id && dispatch(fetchSingleAccessAct(Number(id)));
  }, [dispatch, id]);

  return (
    <MainLayout>
      <Flex overflowY="scroll" maxH="100%" direction="column" gap={10} p={6}>
        <Flex direction="column" gap={4}>
          <Breadcrumb
            fontSize={14}
            separator={<Box w={1} h={1} bg="#8089A2" borderRadius="50%" />}
          >
            <BreadcrumbItem>
              <BreadcrumbLink href="/main">Главная</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink href="/my-documents">Мои документы</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink href="/my-documents/access-acts">Акты допуска</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage color="#8089A2">
              <BreadcrumbLink href={`/my-documents/contracts/${currentAccessAct?.id}`}>
                {currentAccessAct?.act_number &&
                  `Акт допуска № ${currentAccessAct.act_number}`}
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          <Flex justify="space-between" align="center">
            <Flex align="center" gap={4}>
              <ArrowBackIcon boxSize={6} cursor="pointer" onClick={() => goBack("/my-documents/access-acts")} />
              <BlockTitle>
                {currentAccessAct?.act_number &&
                  `Акт допуска № ${currentAccessAct?.act_number}`}
              </BlockTitle>
            </Flex>
          </Flex>
          <SingleAccessActInformationBlock />
        </Flex>

        <Tabs defaultIndex={tabIndex} onChange={setDefaultTabParam}>
          <ChakraTabs tabs={singleAccessActPageTabs} />
          <TabPanels>
            <TabPanel px={0} pb={0}>
              <BuildingObjectsBlock />
            </TabPanel>
            <TabPanel px={0} pb={0}>
              <FilesBlock />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    </MainLayout>
  );
}
