import { Login } from "./pages/Login";
import { PasswordRestore } from "./pages/PasswordRestore";
import { PasswordCreate } from "./pages/PasswordCreate";
import { PasswordRecovery } from "./pages/PasswordRecovery";
import { ProfilePage } from "./pages/ProfilePage";
import { MyDocumentsPage } from "./pages/MyDocumentsPage";
import { ContractsTable } from "./components/MyDocumentsComponents/ContractsTable";
import { SingleContractPage } from "./pages/SingleContractPage";
import { CreateActBasedOnEstimatePage } from "./pages/CreateActBasedOnEstimatePage";
import { AccessActsTable } from "./components/MyDocumentsComponents/AccessActsTable";
import { SingleAccessActPage } from "./pages/SingleAccessActPage";
import { RequirementsAccountPage } from "./pages/RequirementsAccountPage";
import { DocumentsInProgressPage } from "./pages/DocumentsInProgressPage";
import { CompletedWorkActsTable } from "./components/DocumentsInProgressComponents/CompletedWorkActs/CompletedWorkActsTable";
import { InvoiceTable } from "./components/DocumentsInProgressComponents/Invoices/InvoiceTable";
import { SingleInvoicePage } from "./pages/SingleInvoicePage";
import { PaymentTable } from "./components/DocumentsInProgressComponents/Payment/PaymentTable";
import { PurchasePage } from "./pages/PurchasePage";
import { StockCardsTable } from "./components/PurchaseComponents/StockCardsTable";
import { OrderLettersTable } from "./components/PurchaseComponents/OrderLettersTable";
import { CompensationActTable } from "./components/PurchaseComponents/CompensationActTable";
import { SingleStockCardPage } from "./pages/SingleStockCardPage";
import { SingleOrderLetterPage } from "./pages/SingleOrderLetterPage";
import { SingleCompensationActPage } from "./pages/SingleCompensationActPage";
import { ArchivePage } from "./pages/ArchivePage";
import { NotFoundPage } from "./pages/NotFoundPage";
import { NoDataBlock } from "./components/NoDataBlock/NoDataBlock";
import { CreateCompensationActPage } from "./pages/CreateCompensationActPage";
import { SingleWorkCompletedAct } from "./pages/SingleWorkCompletedAct";
import {CreateOrderLetterPage} from "./pages/CreateOrderLetterPage";
import {SingleConstructionRemarkPage} from "./pages/SingleConstructionRemarkPage";
import {CreateStockCardPage} from "./pages/CreateStockCardPage";
import {AddRequestPage} from "./pages/AddRequestPage";
import { RemarksPage } from "./pages/RemarksPage";
import { RemarksTransmissionTable } from "./components/RemarksComponents/RemarksTransmissionTable";
import { SingleRemarkTransmissionPage } from "./pages/SingleRemarkTransmissionPage";
import { RemarksConstructionTable } from "./components/RemarksComponents/RemarksConstructionTable";
import {SingleSettlementsActPage} from "./pages/SingleSettlementsActPage";
import { AdminLogin } from "./pages/AdminLogin";
import { ReportPage } from "./pages/ReportPage";
import { ExecutionContractsComponent } from "./components/ReportComponents/ExecutionContractsComponent";
import { ExecutionEstimatesComponent } from "./components/ReportComponents/ExecutionEstimatesComponent";
import { ExecutionLzkComponent } from "./components/ReportComponents/ExecutionLzkComponent";
import { PaymentSettlementPage } from "./pages/PaymentSettlementPage";
import { MainPage } from "./pages/MainPage";
import { SigningPage } from "./pages/SigningPage";
import ComponentWithProps from "./helpers/ComponentWithProps";
import { CreateActBasedOnActPage } from "./pages/CreateActBasedOnActPage";

export type RouteType = {
  path: string;
  Component: (props?: any) => JSX.Element;
  index?: boolean;
  differentPermissions?: boolean;
  to?: string;
  children?: Array<RouteType>;
  isProtected?: boolean;
  permission?: string | string[];
};

export const routes: RouteType[] = [
  {
    path: "login",
    Component: Login,
  },
  {
    path: "admin-login",
    Component: AdminLogin,
  },
  {
    path: "restore-password",
    Component: PasswordRestore,
  },
  {
    path: "create-password",
    Component: PasswordCreate,
  },
  {
    path: "recovery-password",
    Component: PasswordRecovery,
  },
  {
    path: "main",
    Component: MainPage,
  },
  {
    path: "profile",
    Component: ProfilePage,
  },
  {
    path: "my-documents/",
    Component: MyDocumentsPage,
    index: true,
    to: "contracts",
    children: [
      {
        path: "contracts",
        Component: ContractsTable,
        permission: [ "contract_write"]
      },
      {
        path: "completed-work-acts",
        Component: NoDataBlock,
      },
      {
        path: "invoices",
        Component: NoDataBlock,
      },
      {
        path: "access-acts",
        Component: AccessActsTable,
        // permission: "access_act_write"
      },
      {
        path: "reconciliation-acts",
        Component: NoDataBlock,
      },
      {
        path: "payment",
        Component: NoDataBlock,
      },
      {
        path: "guarantee-letters",
        Component: NoDataBlock,
      },
    ],
  },
  {
    path: "my-documents/contracts/:id",
    Component: ComponentWithProps(SingleContractPage),
  },
  {
    path: "my-documents/contracts/:id/create-act",
    Component: ComponentWithProps(CreateActBasedOnEstimatePage),
  },
  {
    path: "my-documents/contracts/:id/create-act-based-act",
    Component: ComponentWithProps(CreateActBasedOnActPage),
  },
  {
    path: "my-documents/contracts/work-completed-act/:id",
    Component: ComponentWithProps(SingleWorkCompletedAct),
  },
  {
    path: "my-documents/access-acts/:id",
    Component: ComponentWithProps(SingleAccessActPage),
  },
  {
    path: "documents-in-progress/",
    Component: DocumentsInProgressPage,
    index: true,
    to: "completed-work-acts",
    children: [
      {
        path: "completed-work-acts",
        Component: ComponentWithProps(CompletedWorkActsTable),
        permission: ["contract_read", "contract_write"]
      },
      {
        path: "invoices",
        Component: InvoiceTable,
        permission: "invoice_write"
      },
      {
        path: "payment",
        Component: ComponentWithProps(PaymentTable),
        permission: "payment_write"
      },
    ],
  },
  {
    path: "documents-in-progress/invoices/:id",
    Component: ComponentWithProps(SingleInvoicePage),
  },
  {
    path: "purchase",
    Component: PurchasePage,
    index: true,
    to: "stock-cards",
    children: [
      {
        path: "stock-cards",
        Component: StockCardsTable,
      },
      {
        path: "order-letters",
        Component: OrderLettersTable,
      },
      {
        path: "compensation-act",
        Component: ComponentWithProps(CompensationActTable),
      },
    ],
  },
  {
    path: "purchase/stock-cards/:id",
    Component: ComponentWithProps(SingleStockCardPage),
  },
  {
    path: "purchase/stock-cards/create",
    Component: ComponentWithProps(CreateStockCardPage),
  },
  {
    path: "purchase/order-letters/:id",
    Component: ComponentWithProps(SingleOrderLetterPage),
  },
  {
    path: "purchase/order-letters/create",
    Component: ComponentWithProps(CreateOrderLetterPage),
  },
  {
    path: "purchase/compensation-act/:id",
    Component: ComponentWithProps(SingleCompensationActPage),
  },
  {
    path: "purchase/compensation-act/create",
    Component: ComponentWithProps(CreateCompensationActPage),
  },
  {
    path: "archive",
    Component: ArchivePage,
  },
  {
    path: "archive/:id",
    Component: ComponentWithProps(SingleContractPage),
  },
  {
    path: "requirements-account",
    Component: RequirementsAccountPage,
  },
  {
    path: "remarks",
    Component: RemarksPage,
    index: true,
    to: "construction",
    children: [
      {
        path: "construction",
        Component: RemarksConstructionTable,
      },
      {
        path: "transmission",
        Component: RemarksTransmissionTable,
      },
    ],
  },
  {
    path: "/remarks/transmission/:id",
    Component: ComponentWithProps(SingleRemarkTransmissionPage),
  },
  {
    path: "/remarks/construction/:id",
    Component: ComponentWithProps(SingleConstructionRemarkPage),
  },
  {
    path: "add-request",
    Component: AddRequestPage,
  },
  {
    path: "add-request/:id",
    Component: ComponentWithProps(SingleSettlementsActPage),
  },
  {
    path: "report",
    Component: ReportPage,
    index: true,
    to: "execution-contracts",
    children: [
      {
        path: "execution-contracts",
        Component: ExecutionContractsComponent,
      },
      {
        path: "execution-estimates",
        Component: ExecutionEstimatesComponent,
      },
      {
        path: "execution-lzk",
        Component: ExecutionLzkComponent,
      },
    ],
  },
  {
    path: "payment-settlements",
    Component: PaymentSettlementPage,
  },
  {
    path: "sign",
    Component: SigningPage,
  },
  {
    path: "*",
    Component: NotFoundPage,
  },
];
