import { ITab } from "../../components/lib/tabs/Tabs";

export const remarksPageTabs: ITab[] = [
  {
    id: 1,
    href: "construction",
    label: "При строительстве",
    // notice: 3,
  },
  { id: 2,
    href: "transmission",
    label: "При передаче ОН",
    // notice: 1,
  },
];
