import React, {HTMLProps, useEffect, useMemo, useRef, useState} from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { changeEstimate, setCurrentDetails, setTableState } from "../../../store/slices/estimates";
import {
  createColumnHelper,
  CellContext,
  ColumnDef,
} from "@tanstack/react-table";
import { Center, Icon, Stack, Text } from "@chakra-ui/react";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import { ActTable } from "../../lib/table/ActTable";
import { IDetail, IDetailField } from "../../../api/estimates/types";
import styles from "../../lib/input/input.module.scss";
import { formatAmount } from "../../../helpers/formatAmount";
import { Row } from "@tanstack/react-table";

interface MainTableProps {
  searchValue: string;
}

function changeDetail(newDetail: IDetail, updatedDetails: IDetail[]){
  updatedDetails = updatedDetails?.map(detail =>
    (detail.id === newDetail.id) ? newDetail : detail
  );
  return updatedDetails
}

function plusValueForParentsAndLeaf(row: Row<IDetail>, value: number, updatedDetails: IDetail[], newQuantity: string){
  const rowDetail = updatedDetails.find(el => el.id === row.original.id) as IDetail
  const newAmount = Number(Number(rowDetail.Theamount) + value)
  const newPercentage = (1 - ((Number(rowDetail.AmountRemaining) - newAmount) / Number(rowDetail.AmountEstimate))) * 100;
  const updatedDetail = {...rowDetail, quantityinthedocument: Number(newQuantity).toFixed(2),  Theamount: newAmount.toFixed(2), Total: newAmount.toFixed(2), PercentageOfCompletion: newPercentage.toFixed(2)};
  updatedDetails = changeDetail(updatedDetail, updatedDetails)

  let parentRow = row.getParentRow()

  while(parentRow){
    const newDetail = updatedDetails.find(el => el.id === parentRow?.original.id) as IDetail
    newDetail.Theamount = Number(Number(newDetail.Theamount) + value).toFixed(2)
    newDetail.Total = Number(Number(newDetail.Theamount) + value).toFixed(2)
    updatedDetails = changeDetail(newDetail, updatedDetails)

    parentRow = parentRow.getParentRow()
  }

  return updatedDetails
}

const ExpandCell = (info: CellContext<IDetail, string>) => {
  const { currentEstimate } = useAppSelector((state) => state.estimates);
  const dispatch = useAppDispatch();
  const { row, table } = info;
  const checkedRows = table.getSelectedRowModel().flatRows;

  useEffect(() => {
    const detailIds = checkedRows.map((row) => row.original.id);
    const checkedDetails = currentEstimate?.details?.filter((detail) => detailIds.includes(detail.id)) || [];
    dispatch(setCurrentDetails(checkedDetails));
    dispatch(setTableState(table.getState()));
  }, [checkedRows, dispatch, currentEstimate?.details]);

  function findAllLeafs(row: Row<IDetail>, updatedDetails: IDetail[]){

    if(!row.getCanExpand()){

      const rowDetail = updatedDetails?.find(el => el.id === row.original.id) as IDetail

      const value = row.getIsSelected() ? (0 - Number(rowDetail.quantityinthedocument)) * Number(rowDetail.Price) : Number(rowDetail.Thenumberofstops) * Number(rowDetail.Price)

      const newQuantity = row.getIsSelected() ? "0.00" : rowDetail.Thenumberofstops
      updatedDetails = plusValueForParentsAndLeaf(row, value, updatedDetails, newQuantity)
    }
    else{
      row.subRows.forEach((el) => {
        if(el.getIsSelected() === row.getIsSelected()){
          updatedDetails = findAllLeafs(el, updatedDetails)
        }
      })
    }

    return updatedDetails

  }

  function checkboxClick(row: Row<IDetail>){

    var updatedDetails: IDetail[] = JSON.parse(JSON.stringify(currentEstimate?.details))
    updatedDetails = findAllLeafs(row, updatedDetails)

    const updatedEstimate = {...currentEstimate, details: updatedDetails};
    dispatch(changeEstimate(updatedEstimate));
    row.toggleSelected(!row.getIsSelected())

  }

  useEffect(() => {
    if (Number(row.original.Theamount) > 0) {
      if (row.getCanExpand()) {
        if (!row.getIsExpanded()) {
          row.getToggleExpandedHandler()()
        }
      } else {
        row.toggleSelected(true)
      }
    }
  }, [row]);
  return (
    <Stack spacing={5} direction="row" align="center">
      <IndeterminateCheckbox
        {...{
          disabled: !Number(row.getValue("Thenumberofstops")) && !row.getCanExpand(),
          checked: row.getIsSelected(),
          indeterminate: row.getIsSomeSelected(),
          onClick: () => checkboxClick(row),
          onChange: row.getToggleSelectedHandler()
        }}
      />
      {row.getCanExpand() && (
        <Icon
          cursor="pointer"
          mr={2}
          fontSize="12px"
          as={row.getIsExpanded() ? MinusIcon : AddIcon}
          onClick={row.getToggleExpandedHandler()}
        />
      )}
    </Stack>
  );
};

const WorkTypeCell = (info: CellContext<IDetail, string>) => (
  <Text>{info.getValue()}</Text>
);

const CenterCell = (text: string) => <Center w="full">{text}</Center>;
const ConstructionObjectHeader = () => <Text>Объект<br />стр-ва</Text>

const QuantityInDocumentCell = (info: CellContext<IDetail, string>) => {
  const {row} = info;
  const dispatch = useAppDispatch();
  const {currentEstimate} = useAppSelector((state) => state.estimates);
  const rowDetail = currentEstimate?.details?.find(el => el.id === row.original.id) as IDetail
  const [value, setValue] = useState(row.original.quantityinthedocument);

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const rawValue = e.target.value;
    let formatValue = rawValue.replace(/[^0-9.]/g, "").replace(/^0+(?!\.)|\.*$/g, "");
    if (Number(formatValue) > Number(rowDetail?.Thenumberofstops)) {
      formatValue = rowDetail?.Thenumberofstops
    }
    setValue(formatValue);
    const diff = (Number(formatValue) - Number(rowDetail.quantityinthedocument)) * Number(rowDetail.Price)
    var updatedDetails: IDetail[] = JSON.parse(JSON.stringify(currentEstimate?.details))

    updatedDetails = plusValueForParentsAndLeaf(row, diff, updatedDetails, formatValue)
    
    if (Number(formatValue) > 0) {
      info.row.toggleSelected(true);
    } else {
      info.row.toggleSelected(false);
    }

    const updatedEstimate = {...currentEstimate, details: updatedDetails};
    dispatch(changeEstimate(updatedEstimate));

  }

  useEffect(() => {
    setValue(String(Number(rowDetail.quantityinthedocument).toFixed(2)))
  }, [rowDetail]);

  return !!row.subRows.length ? null : (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.input_container}>
          <input
            name={`quantityinthedocument_${row.original.id}`}
            value={value}
            onChange={(e) => setValue(e.target.value.replace(/[^0-9.]/g, ""))}
            onBlur={handleBlur}
            onKeyDown={(e) => e.key === 'Enter' && (e.target as HTMLInputElement).blur()}
            style={{
              maxWidth: "90px"
            }}
          />
        </div>
      </div>
    </div>
  );
};

const DocumentAmountCell = (info: CellContext<IDetail, string>) => {
  const { currentEstimate} = useAppSelector((state) => state.estimates);
  const detailId = Number(info.row.original.id);
  const [amount, setAmount] = useState("");

  useEffect(() => {
    const detail = currentEstimate?.details?.find(detail => detail.id === detailId);
    setAmount(formatAmount(detail?.Theamount));
  }, [currentEstimate, detailId]);

  return <Text>{amount}</Text>;
};

const PercentageOfCompletionCell = (info: CellContext<IDetail, string>) => {
  const { currentEstimate} = useAppSelector((state) => state.estimates);
  const details = currentEstimate?.details;
  const detailId = Number(info.row.original.id);

  return <Text>{formatAmount(details?.find(detail => detail.id === detailId)?.PercentageOfCompletion)}</Text>;
}

const CodifierCell = (info: CellContext<IDetail, string>) => {
  return <Text>{info.renderValue()}</Text>
}

export function MainTable({ searchValue }: MainTableProps) {
  const searchFields: IDetailField[] = useMemo(() => [
    "Codifier", "TypeofWorkName", "UnitOfMeasurementName", "ConstructionObjectName", "Price",
    "Quantity", "AmountEstimate", "Thenumberofstops", "AmountRemaining",
    "quantityinthedocument", "PercentageOfCompletion"
  ], []);

  const { currentEstimate } = useAppSelector((state) => state.estimates);

  const columnHelper = createColumnHelper<IDetail>();

  const [filteredDetails, setFilteredDetails] = useState([] as IDetail[]);

  const addDepth = (details: IDetail[]) => {
    const tree: IDetail[] = [];
    let currContainers: IDetail[] = []; // контейнер одной из группы(1 или 2 или 3 или N)

    while (details.length) {
      const curr = details.shift();
      if(!curr) break;

      if(!curr.Codifier.includes('.') ){ // is root element 1, 2, 3, etc
        currContainers = [curr]; // работа с поддеревом root эл-та
        tree.push(curr);
        continue;
      }

      const currCount = curr.Codifier.split('.').length;
      let nextCount = 0;

      if(details[0]) { // curr не последний эл-нт
        nextCount = details[0].Codifier.split('.').length;
      }
      else { // последний эл-нт

        if (!currContainers[currContainers.length - 1].subRows){ // 5 => 5.1
          currContainers[currContainers.length - 1].subRows  = [] as IDetail[];
        }
        // @ts-ignore
        currContainers[currContainers.length - 1].subRows.push(curr);

        break;
      }

      if(currCount > nextCount) { // было 1.1.1 следующий 1.2
        if(!currContainers[currContainers.length - 1].subRows) {
          currContainers[currContainers.length - 1].subRows  = [] as IDetail[];
        }
        // @ts-ignore
        currContainers[currContainers.length - 1].subRows.push(curr);
        currContainers.length = nextCount - 1;

      }
      else if(currCount < nextCount) { // текущий = 1.1, следующий = 1.1.1 . Это контейнер
        if(!currContainers[currContainers.length - 1].subRows) {
          currContainers[currContainers.length - 1].subRows  = [] as IDetail[];
        }

        // @ts-ignore
        currContainers[currContainers.length - 1].subRows.push(curr);
        currContainers.push(curr);
      }
      else { // очередной конечный эл-нт . 1.1.1 -> 1.1.2 -> 1.1.3 и тд
        if(!currContainers[currContainers.length - 1].subRows) {
          currContainers[currContainers.length - 1].subRows  = [] as IDetail[];
        }
        // @ts-ignore
        currContainers[currContainers.length - 1].subRows.push(curr);
      }

    }

    return tree;
  };

  useEffect(() => {
    const filtered = currentEstimate?.details?.filter((detail: IDetail) =>
      searchFields.some((field) =>
        String(detail[field]).toLowerCase().includes(String(searchValue).toLowerCase())
      )
    );

    // const sortedDetails = [...(filtered || [])];
    filtered?.sort((a, b) => {
      const codifierA = a.Codifier || '';
      const codifierB = b.Codifier || '';
      return codifierA.localeCompare(codifierB, undefined, { numeric: true, sensitivity: 'base' });
    });

    if(currentEstimate?.details?.length) {
      setFilteredDetails(addDepth(JSON.parse(JSON.stringify(filtered))));
    }

  }, [currentEstimate?.details, currentEstimate?.details?.length, searchFields, searchValue]);

  const columns = useMemo<Array<ColumnDef<IDetail, string>>>(
    () => [
      columnHelper.group({
        id: "info",
        columns: [
          {
            id: "column",
            cell: ExpandCell,
          },
          columnHelper.accessor("Codifier", {
            header: "Номер",
            cell: CodifierCell,
          }),
          columnHelper.accessor("TypeofWorkName", {
            header: "Вид работ",
            cell: WorkTypeCell,
          }),
          columnHelper.accessor("UnitOfMeasurementName", {
            header: "Ед.изм.",
          }),
          columnHelper.accessor("ConstructionObjectName", {
            header: ConstructionObjectHeader
          }),
          columnHelper.accessor("Price", {
            header: "Цена",
            cell: (info) => (!info.row.getCanExpand() ? formatAmount(info.getValue()) : "")
          }),
        ],
      }),
      columnHelper.group({
        id: "estimate",
        header: () => CenterCell("Смета"),
        columns: [
          columnHelper.accessor("Quantity", {
            header: "Количество",
            cell: (info) => (!info.row.getCanExpand() ? formatAmount(info.getValue()) : "")
          }),
          columnHelper.accessor("AmountEstimate", {
            header: "Сумма",
            cell: (info) => formatAmount(info.getValue())
          }),
        ],
      }),
      columnHelper.group({
        id: "balance",
        header: () => CenterCell("Остаток"),
        columns: [
          columnHelper.accessor("Thenumberofstops", {
            header: "Количество",
            cell: (info) => (!info.row.getCanExpand() ? formatAmount(info.getValue()) : "")
          }),
          columnHelper.accessor("AmountRemaining", {
            header: "Сумма",
            cell: (info) => formatAmount(info.getValue())
          }),
        ],
      }),
      columnHelper.group({
        id: "document",
        header: () => CenterCell("В документ"),
        columns: [
          columnHelper.accessor("quantityinthedocument", {
            header: "Количество",
            cell: QuantityInDocumentCell,
            meta: {cellClassName: "quantity-in-document"}
          }),
          columnHelper.accessor("Document_Amount", {
            header: "Сумма",
            cell: DocumentAmountCell,
          }),
        ],
      }),
      columnHelper.accessor("PercentageOfCompletion", {
        header: "% выполнения",
        cell: PercentageOfCompletionCell,
      }),
    ],
    [columnHelper]
  );

  return (
    <ActTable
      data={filteredDetails}
      columns={columns}
      searchValue={searchValue}
    />
  );
}

function IndeterminateCheckbox({
  indeterminate,
  className = "",
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate, rest.checked]);

  return (
    <input
      type="checkbox"
      ref={ref}
      style={{
        width: "20px",
        height: "20px",
        accentColor: "teal",
        cursor: "pointer",
      }}
      {...rest}
    />
  );
}
