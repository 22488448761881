import { Navigate, Route, Routes } from "react-router-dom";
import { Layout } from "./components/Layout/Layout";
import {routes, RouteType} from "./routes";
import { useEffect } from "react";
import { GlobalModalWrapper, GlobalModal } from 'react-global-modal'
import 'react-global-modal/dist/style.css'
import { ToastContainer } from "react-toastify";
import {RootState, useAppDispatch, useAppSelector} from "./store";
import {checkPermission} from "./helpers/checkPermission";
import { Tooltip } from "react-tooltip";
import { fetchTooltipList } from "./store/slices/tooltips";

let globalModalRef: any = null

function App() {
  const { permissions } = useAppSelector(
    (state: RootState) => state.auth.authData
  );
  const dispatch = useAppDispatch()

  const findRedirectRoute = (children: RouteType[] | undefined): string => {
    const foundChild = children && children.find(route => route.permission && checkPermission(route.permission, permissions))
    return foundChild ? foundChild.path : ""
  }

  useEffect(() => {
    GlobalModal.setUpModal(globalModalRef)
    localStorage.getItem("token") !== null && dispatch(fetchTooltipList())
  }, [])

  return (
    <>
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Navigate to="/login" replace />} />
        {routes.map((route) => {
          const { path, Component, children, index, to = "", differentPermissions } = route;

          return (
            <Route key={path} path={path} element={<Component />}>
              {index && <Route index element={<Navigate to={to} />} />}
              {!!children?.length &&
                children.map((child) => (
                  <Route
                    key={child.path}
                    path={child.path}
                    element={<child.Component />}
                  />
                ))}
            </Route>
          );
        })}
      </Route>
    </Routes>
    <GlobalModalWrapper ref={(el) => (globalModalRef = el)} />
    <ToastContainer></ToastContainer>
      <Tooltip className="tooltip"
        clickable={true}
        globalCloseEvents={{ scroll: true }}
        closeEvents={{ mouseleave: true }}
        id="tooltip"
        delayShow={500}
        style={{
          background: "#343B4C",
          fontSize: "14px",
          lineHeight: "18px",
          padding: "16px 20px",
          borderRadius: "8px",
          zIndex: 4,
          maxWidth: "700px",
        }}
        place="top-start"
        opacity={1}
        classNameArrow="tooltip_arrow"
      />
    </>
  );
}

export default App;
