import {useEffect, useMemo} from "react";
import {createColumnHelper, Row} from "@tanstack/react-table";
import { FilterHeader } from "../Filter/FilterHeader";
import { Table } from "../lib/table/Table";
import {RootState, useAppDispatch, useAppSelector} from "../../store";
import {ILimitFenceCard} from "../../api/limitFenceCards/types";
import {
  fetchLimitFenceCards,
  fetchSingleLimitFenceCard,
  resetCurrentLimitFenceCard
} from "../../store/slices/limitFenceCards";
import dayjs from "dayjs";
import {IEstimate} from "../../api/estimates/types";
import {ContractCell} from "../ContractCell/ContractCell";
import { StatusCellTable } from "../StatusCellTable/StatusCellTable";
import { filterFunction } from "../../helpers/filterFunction";

const columnHelper = createColumnHelper<ILimitFenceCard>();

export function StockCardsTable() {
  const dispatch = useAppDispatch();
  const { limitFenceCards, currentLimitFenceCard } = useAppSelector((state: RootState) => state.limitFenceCards);

  const handleStockCardsTableRowClick = (row: Row<IEstimate>) => {
    row.toggleSelected();
    if (row.getIsSelected()) {
      dispatch(resetCurrentLimitFenceCard());
    } else {
      dispatch(fetchSingleLimitFenceCard(row.original.id));
    }
  };

  useEffect(() => {
    return () => {
      dispatch(resetCurrentLimitFenceCard());
    };
  }, []);

  console.log(currentLimitFenceCard?.id)

  const columns = useMemo(
    () => [
      columnHelper.accessor("status", {
        header: (info) => FilterHeader(info.column, info.table,  "Статус"),
        cell: (info) => {
          if (info.renderValue() === "Рабочее") {
            return StatusCellTable(info.renderValue(), "#FEE7AC")
          } else if (info.renderValue() === "Отклонена") {
            return StatusCellTable(info.renderValue(), "#ED5E64")
          } else if (info.renderValue() === "Утверждена") {
            return StatusCellTable(info.renderValue(), "#AAE2DB")
          } else {
            return StatusCellTable(info.renderValue(), "#469DDD40")
          }
        },
        enableColumnFilter: true,
        filterFn: ({ original: { status } }, id, filterValues) =>
          filterFunction({ value: status, filterValues }),
        meta: {
          filterSelect: true
        },
      }),
      columnHelper.accessor("number", {
        header: "Номер",
        enableColumnFilter: true,
      }),
      columnHelper.accessor("period_from", {
        header: "Дата начала",
        cell: (info) => dayjs(info.renderValue()).format("DD.MM.YYYY"),
        enableSorting: true,
      }),
      columnHelper.accessor("period_to", {
        header: "Дата окончания",
        cell: (info) => dayjs(info.renderValue()).format("DD.MM.YYYY"),
        enableSorting: true,
      }),
      columnHelper.accessor("a101_org_name", {
        header: (info) => FilterHeader(info.column, info.table, "Организация"),
        enableColumnFilter: true,
        filterFn: ({ original: { a101_org_name } }, id, filterValues) =>
          filterFunction({ value: a101_org_name, filterValues }),
        meta: {
          filterSelect: true
        }
      }),
      columnHelper.accessor("contract_name", {
        header: "Договор",
        cell: (info) => ContractCell(info, info.cell.row.original.contract_id),
        enableColumnFilter: true,
      }),
      columnHelper.accessor("project_name", {
        header: (info) => FilterHeader(info.column, info.table, "Проект"),
        enableColumnFilter: true,
        filterFn: ({ original: { project_name } }, id, filterValues) =>
          filterFunction({ value: project_name, filterValues }),
        meta: {
          filterSelect: true
        }
      }),
      columnHelper.accessor("construction_objects", {
        header: "Объект строительства",
      }),
    ],
    []
  );

  return <Table
    minHeight="300px"
    data={limitFenceCards}
    columns={columns}
    rowClick={handleStockCardsTableRowClick}
    fetchFunction={fetchLimitFenceCards}
    lastViewedDocument={currentLimitFenceCard?.id}
  />;
}
